/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import LinkList from 'gatsby-core-theme/src/components/molecules/link-list';
import { translate } from '~helpers/getters';
import { Context } from '~context/MainProvider';

import { copyrightText } from 'gatsby-core-theme/src/helpers/getters';
import Disclaimer from 'gatsby-core-theme/src/components/atoms/disclaimer';
import handshake from '../../../../../../../static/images/handshake.png';
import LazyImage from 'gatsby-core-theme/src/hooks/lazy-image';
import styles from './template-one.module.scss';

const TemplateOne = ({ footerMenu, showLinks, footerLogos, socialIcons, showCopyright, logo }) => {
  const { translations } = useContext(Context) || {};

  return (
    <footer className={styles.footer}>
      <div className={styles.footerInfo}>
        <LazyImage
          src={logo}
          alt="casinolisten.de Logo"
          width={227}
          height={40}
          className={styles.image}
        />
        {footerLogos && (
          <>
          <LinkList
            width={40}
            height={40}
            singleList
            imageOnly
            lists={footerLogos}
            gtmClass="mobile-menu-gtm logo-cta"
          />
          <Disclaimer dmcaImage='../../../../../images/dmca.png' imgWidth={100} imgHeight={30} />
          </>
        )}
        {showCopyright && (
          <p className={styles?.copyrightText || ''}>
            {translate(translations, 'copy_right_footer', 'Copyright © 2023')}
          </p>
        )}
      </div>

      {footerMenu && showLinks && (
        <div className={styles?.links || ''}>
          <LinkList lists={footerMenu} singleList gtmClass="mobile-menu-gtm" />
        </div>
      )}

      <div className={styles.footerBox}>
        <LazyImage
          src={handshake}
          alt="Shaking Hands"
          width={48}
          height={48}
          className={styles.hands}
        />
        <p> {translate(translations, 'footer_banner', 'Nur 100 % lizenzierte Angebote')}</p>
      </div>
    </footer>
  );
};

export default TemplateOne;

TemplateOne.propTypes = {
  footerLogos: PropTypes.shape({}),
  footerMenu: PropTypes.shape({
    children: PropTypes.shape([]),
  }),
  copyrightDisclaimer: PropTypes.string,
  showLinks: PropTypes.bool,
  asSeenOn: PropTypes.shape({}),
  gamblingText: PropTypes.string,
  socialIcons: PropTypes.shape({}),
  hasFooterLinks: PropTypes.bool,
  hasDisclaimer: PropTypes.bool,
  showCopyright: PropTypes.bool,
  helpText: PropTypes.string,
  phoneNum: PropTypes.string,
  logo: PropTypes.string,
  dmcaImage: PropTypes.string,
  dmcaWidth: PropTypes.number,
  dmcaHeight: PropTypes.number,
  securityIcon: PropTypes.string,
};
